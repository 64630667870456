import { getCampaigns, getCampaignsWithoutIncludes } from './getCampaigns';
import { getCampaignById } from './getCampaignById';
import { createCampaign } from './createCampaign';
import { updateCampaign } from './updateCampaign';
import { deleteCampaign } from './deleteCampaign';
import { ecommerce } from '..';

export const api = ecommerce;

export { getCampaigns, getCampaignById, createCampaign, updateCampaign, deleteCampaign, getCampaignsWithoutIncludes };
