/// <reference path="../../../../entities/ecommerce/Campaign.js" />
import { api } from '.';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import { deleteCampaignShared } from '@/services/shared/campaign';

/**
 *
 * @param {Campaign} campaign
 * @returns {Promise.<ResponseBuilder>}
 */
export async function deleteCampaign(campaign) {
  const {
    data: {
      campaign: { id },
    },
  } = await deleteCampaignShared(api, campaign);
  return new ResponseBuilder(id);
}
