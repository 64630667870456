import { CAMPAIGNS_RESOURCE } from '../../../keys';
import { api } from '.';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 * @param {string} campaignId
 */
export const getResources = () => ({
  [CAMPAIGNS_RESOURCE]: undefined,
});

/**
 *
 * @param {object} params
 * @returns {Promise.<ResponseBuilder>}
 */
export async function getCampaigns(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  params.addInclude('client');
  params.addFilter('autogenerated', 0);

  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
/**
 *
 * @param {object} params
 * @returns {Promise.<ResponseBuilder>}
 */
export async function getCampaignsWithoutIncludes(params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources());
  params.addFilter('autogenerated', 0);

  const { data } = await api.get(partialUrl, params.build());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
