import { CAMPAIGNS_RESOURCE } from '@/services/keys';

/**
 *
 * @param {Obelix} api
 * @param {Campaign} campaign
 * @returns {Promise.<{object}>}
 */
export async function createCampaignShared(api, campaign) {
  const partialUrl = api.createUrl({
    [CAMPAIGNS_RESOURCE]: campaign.id,
  });
  return await api.create(partialUrl, campaign.payload());
}
