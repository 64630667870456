/// <reference path="../../../../entities/ecommerce/Campaign.js" />

import { CAMPAIGNS_RESOURCE } from '../../../keys';
import { api } from '.';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import ecommerceEntityParser from '@/entities/ecommerce/EcommerceEntityParser';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 * @param {string} campaignId
 */
export const getResources = campaignId => ({
  [CAMPAIGNS_RESOURCE]: campaignId,
});

/**
 * @param {string} campaignId
 * @returns {Promise.<ResponseBuilder>}
 */
export async function getCampaignById(campaignId, params = new QueryParamsBuilder()) {
  const partialUrl = api.createUrl(getResources(campaignId));
  params.addInclude('client');

  const { data } = await api.get(partialUrl, params.buildWithoutPage());
  return new ResponseBuilder(ecommerceEntityParser.parse(data), data.meta).build();
}
